
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































































.panel-hardware {
  --padding: #{$spacing * 1.5};

  width: 100vw;
  min-height: $dynamic-100vh;
  padding: 6rem 0;
  background: $white;

  @include mq(m) {
    width: 80vw;
    max-width: 110rem;
  }

  @include mq(l) {
    --padding: #{$spacing * 3};
  }

  @include mq(xl) {
    --padding: #{$spacing * 5.6};
  }
}

.panel-hardware__bundles {
  // Increase CSS specificity to make sure we overide rules from Bundles component
  &.panel-bundles {
    width: auto;
    height: auto;
    min-height: 0;
    padding: 0;
  }
}

.panel-hardware__gift-outer {
  position: relative;
  z-index: 2;
  margin-top: calc(var(--padding) * 2);
  padding: calc(var(--padding) + #{$spacing}) var(--padding) 0;
  border-top: 1px solid $c-blue-light-pale;

  &::before {
    @extend %text-center;
    @extend %fw-bold;
    @include center-xy;

    content: '+';
    z-index: 1;
    top: 0;
    width: 3.6rem;
    height: 3.6rem;
    color: $white;
    line-height: 3.6rem;
    background: $c-bunting;
    border-radius: 50%;
  }

  @include mq(l) {
    margin: calc(var(--padding) * 0.5) var(--padding);
  }

  @include mq(l) {
    padding-top: $spacing * 4;
  }
}

.panel-hardware__gift {
  position: relative;
  padding: 0;
  color: $white;
  background: $c-bunting;
  border-radius: 0.8rem;

  @include mq(l) {
    background: $c-promo-card;
  }
}

.panel-hardware__gift-inner {
  position: relative;
  padding: $spacing * 1.75 $spacing * 1.25 0 $spacing * 1.25;

  @include mq(xs) {
    padding-bottom: $spacing * 1.75;
  }

  @include mq(l) {
    padding: $spacing * 2 40% $spacing * 2 $spacing * 2.5;
  }
}

.panel-hardware__gift__background-outer {
  @include get-all-space;

  overflow: hidden;
  border-radius: 0.8rem;
}

.panel-hardware__gift__background {
  display: block;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.panel-hardware__gift__label {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(-1.5rem, -50%);

  &::after {
    position: absolute;
    top: calc(100% - 3px);
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    /* stylelint-disable-next-line scss/no-global-function-names */
    background-color: darken($c-pink-medium, 30%);
    clip-path: polygon(0% 0%, 100% 0, 100% 75%);
    content: '';
  }

  ::v-deep [class*='label'] {
    font-size: 1.3rem;
  }
}

.panel-hardware__gift__title {
  margin: 0;
  font-family: $ff-alt;

  ::v-deep {
    @include owl;

    strong {
      @extend %fw-bold;
    }
  }
}

.panel-hardware__gift__picture {
  position: relative;
  z-index: 1;
  margin: 0;
  padding-top: $spacing;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include mq('xs') {
    @include center-y;

    right: 2.5rem;
    height: 100%;
    padding: 0.5rem 0;
  }

  @include mq('l') {
    right: 4rem;
  }
}

.panel-hardware__gift__text {
  ::v-deep {
    @include owl;
  }
}

.panel-hardware__gift__speedline {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  max-height: 80%;

  ::v-deep {
    path {
      transform: translateY(80%);
    }
  }

  .gray & {
    fill: $c-gray;
  }

  .blue & {
    fill: $c-light-blue;
  }

  .paleblue & {
    fill: $c-blue-pale;
  }

  .cyan & {
    fill: $c-cyan-contrast;
  }

  .orange & {
    fill: $c-orange;
  }

  .burgundy & {
    fill: $c-burgundy;
  }

  .midnightblue & {
    fill: $c-blue-dark;
  }

  .pink & {
    fill: $c-pink-medium;
  }

  .lightpink & {
    fill: $c-pink-light;
  }

  .purple & {
    fill: $c-purple;
  }

  .gift-color-a & {
    fill: $c-gift-color-a;
  }

  .gift-color-b & {
    fill: $c-gift-color-b;
  }

  .gift-color-c & {
    fill: $c-gift-color-c;
  }
}

.panel-hardware ::v-deep {
  .product-card {
    margin-right: $spacing;
  }

  .flickity-button {
    position: absolute;
    visibility: hidden;

    @include mq(l) {
      z-index: 2;
      visibility: visible;

      &.previous {
        top: 14rem;
        left: calc(var(--padding) * -1.5);
        transform: none;
      }

      &.next {
        top: 14rem;
        right: calc(var(--padding) * -1.5);
        transform: none;
      }
    }

    @include mq(xl) {
      &.previous {
        left: calc(var(--padding) * -1);
      }

      &.next {
        right: calc(var(--padding) * -1);
      }
    }
  }
}
